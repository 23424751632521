<template>
  <div>
    <CModalExtended 
      :title="!isDetails ? `${$t('label.error')}: ${File.FileName ?? ''}` : $t('label.error')"
      color="dark"
      :closeOnBackdrop="false" 
      :show.sync="ModalActive" 
      size="lg"
      @update:show="$emit('Close')"
    >
      <CRow>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            size="lg"
            :fields="fields"
            :items="computedList"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
          >
          </dataTableExtended>
        </CCol>
      </CRow>
      <template #footer>
        <CButton 
          shape="square" 
          color="wipe" 
          @click="$emit('Close')"
        >
          <CIcon name="x" />&nbsp; {{ $t("button.exit") }}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import General from "@/_mixins/general";
import { mapState } from 'vuex';
//DATA
function data() {
  return {
    ModalActive: false,
  };
}

//COMPUTED
function fields(){
  if (this.isDetails) {
    return [
      { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:3%;', filter: false},
      { label: this.$t('label.column'), key: 'ColumnName', _classes: 'text-center', _style:'width:30%' },
      { label: this.$t('label.error'), key: 'ErrorMessage', _classes: 'text-center', _style:'width:67%' },
    ];
  } else {
    return [
      { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:3%;', filter: false},
      { label: this.$t('label.booking'), key: 'BlNro', _classes: 'text-center', _style: 'width:25%;',},
      { label: this.$t('label.row'), key: 'ROW', _classes: 'text-center', _style:'width:10%' },
      { label: this.$t('label.column'), key: 'ColumnName', _classes: 'text-center', _style:'width:20%' },
      { label: this.$t('label.error'), key: 'ErrorMessage', _classes: 'text-center', _style:'width:42%' },
    ];
  }
}

function computedList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let ErrorJson = this.File?.ErrorJson ?? [];
  return ErrorJson.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      BlNro: item.BlNro ?? '',
      ErrorMessage: item['ErrorMessage'+_lang] ?? '',
    };
  });
}

export default {
  name: "modal-error",
  mixins: [General],
  data,
  props: {
    modal: Boolean,
    File: {
      type: Object,
      default: () => {},
    },
    isDetails: Boolean,
  },
  computed: {
    fields,
    computedList,
    ...mapState({
      planificacionId: (state) => state.planificacionestiba.planificacionId,
      myDataItinirary: state => state.planificacionestiba.myDataItinirary,
    })
  },
  watch: {
    modal: function (NewVal) {
      this.ModalActive = NewVal;
    },
  },
};
</script>